<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준년월</div>
              <DxDateBox
                :edit-enabled="false"
                :styling-mode="stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.startDt"
                type="date"
                display-format="yyyy.MM"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                maxLength="7"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayStartChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxDateBox
                :styling-mode="stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.endDt"
                type="date"
                display-format="yyyy.MM"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="7"
                :min="searchType.customTypes.startDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayEndChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>

      <div class="flex space-x-4">
        <div class="w-2/5">
          <esp-dx-data-grid :data-grid="stdGrid" ref="stdGrid" @row-click="onRowClick" />
        </div>
        <div class="w-3/5">
          <esp-dx-data-grid :data-grid="metricsGrid" ref="metricsGrid" @saving="e => onSaveMetrics(e)" />
        </div>
      </div>

      <!-- Modal Layer -->
      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.hasOwnProperty('save')
                : false
              : false
          "
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.text
                : ''
              : '',
            width: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.width
                : 120
              : 120,
            height: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.height
                : 40
              : 40,
            onClick: () => {
              onConfirmModal();
            },
          }"
        />
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.width
                : 120
              : 120,
            height: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.height
                : 40
              : 40,
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </DxPopup>
      <!-- /Modal Layer -->
    </div>
  </div>
</template>

<script>
  import { DxSwitch } from 'devextreme-vue/switch';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import ModalPerformMetrics from '@/pages/ewm/kpi/setting/perform-std/modal-perform-metrics.vue';
  import ModalEwmAdd from '@/pages/ewm/productivity/implementation/evaluation-schedule/modal-ewm-add';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  const gridData = {
    columnDataSource: {
      viewFl: [
        { cd: 'Y', nm: '표시' },
        { cd: 'N', nm: '미표시' },
      ],
    },
  };

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      DxPopup,
      DxToolbarItem,
      ModalPerformMetrics,
      ModalEwmAdd,
    },
    data() {
      return {
        canAddStandard: false,
        codes: {
          performEvalDivision: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            startDt: getPastFromToday(3, 'months'),
            endDt: getPastFromToday(-3, 'months'),
          },
          paramsData: {},
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        stdGrid: {
          keyExpr: 'kpiPerformStdId',
          refName: 'stdGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: 'calc(100vh - 370px)',
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellHoverChanged: false,
            cellPrepared: false,
            checkDataBeforeSaving: false,
            initNewRow: true,
            rowClick: true,
            rowInserted: false,
            rowInserting: false,
            saving: true,
            selectionChanged: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '복사생성',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 70,
                  height: 30,
                  onClick() {
                    vm.onCopyStdGrid();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onAddStdGrid();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteStdGridData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            // allowUpdating: options => {
            // 	if (options.row.rowType == 'data' && options.row.data.useFl === this.$_enums.common.stringUsedFlag.YES.value) {
            // 		return false;
            // 	} else {
            // 		return true;
            // 	}
            // }, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowUpdating: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '기준년월',
              dataField: 'ymd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              dataType: 'date',
              format: 'yyyy.MM',
              editorOptions: {
                dataType: 'date',
                calendarOptions: {
                  zoomLevel: 'year',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'year',
                },
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '성과관리기준',
              dataField: 'performStdNm',
              alignment: 'left',
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
              cellTemplate: (container, options) => {
                if (options.data.useFl === this.$_enums.common.stringUsedFlag.NO.value) {
                  let aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    vm.onUpdateData(options.data);
                  });
                  container.append(aTag);
                } else {
                  container.append(options.value);
                }
              },
            },
            {
              caption: '배점',
              dataField: 'score',
              alignment: 'center',
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '표시여부',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
              },
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
                    disabled: true,
                    // disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
                    onValueChanged: e => {
                      const viewFlVal = e.value
                        ? this.$_enums.common.stringUsedFlag.YES.value
                        : this.$_enums.common.stringUsedFlag.NO.value;
                      this.$refs.stdGrid.getGridInstance.cellValue(options.rowIndex, 'viewFl', viewFlVal);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
              /* editCellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === 'Y',
									disabled: options.data.useFl === 'Y' ? true : false,
									onValueChanged: e => {
										const viewFlVal = e.value ? 'Y' : 'N';
										options.setValue(viewFlVal);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						},
						lookup: {
							dataSource: gridData.columnDataSource.viewFl,
							displayExpr: 'nm',
							valueExpr: 'cd',
						}, */
            },
          ],
        },
        metricsIdFilter: [],
        metricsGrid: {
          refName: 'metricsGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: 'calc(100vh - 370px)',
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            initNewRow: false,
            saving: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '기준추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 85,
                  height: 30,
                  onClick() {
                    vm.onAddStandard();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteMetricsData();
                  },
                },
                // sortIndex: 10,
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: options => {
              return vm.canAddStandard;
            }, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'kpiPerformMetricsId',
              dataField: 'kpiPerformMetricsId',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '성과관리지표',
              dataField: 'kpiPerformMetricsNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '자동여부',
              dataField: 'autoFl',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return rowData.autoFl == this.$_enums.common.stringUsedFlag.YES.value ? '자동' : '수동';
              },
              lookup: {
                dataSource: [
                  {
                    label: '자동',
                    value: 'Y',
                  },
                  {
                    label: '수동',
                    value: 'N',
                  },
                ],
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '평가방식',
              dataField: 'performEvalDivision',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              lookup: {
                dataSource: this.$_enums.ewm.performEvalDivision.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              calculateSortValue: data => this.$_enums.ewm.performEvalDivision.values.find(e => data.performEvalDivision == e.value).label,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '기준점수',
              dataField: 'baseScore',
              dataType: 'number',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              width: '110',
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '최저점',
              dataField: 'minScore',
              dataType: 'number',
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: true,
              width: '90',
              requiredRule: {
                message: '필수 항목입니다.',
              },
              customRule: {
                message: '최저점은 최고점이하로 입력하세요.',
                callback: options => vm.validCheck(options),
              },
            },
            {
              caption: '최고점',
              dataField: 'maxScore',
              dataType: 'number',
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: true,
              width: '90',
              requiredRule: {
                message: '필수 항목입니다.',
              },
              customRule: {
                message: '최고점은 최저점이상으로 입력하세요.',
                callback: options => vm.validCheck(options),
              },
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      validCheck(options) {
        return options.data.maxScore && options.data.minScore && options.data.maxScore < options.data.minScore ? false : true;
      },
      /** @description : stdGrid 데이터 검색 메서드 */
      async selectDataList() {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        this.$refs.stdGrid.getGridInstance.cancelEditData();
        this.$refs.stdGrid.getGridInstance.deselectAll();

        const payload = {
          actionname: 'EWM_PERFORMANCE_STD_SELECT',
          data: {
            startDt: vm.searchType.customTypes.startDt,
            endDt: vm.searchType.customTypes.endDt,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          // res.data.data.forEach((r,index) => {
          // 	r.no = index+1;
          // })
          this.stdGrid.dataSource = res.data.data;
          this.metricsGrid.dataSource = [];
        }
      },
      /** @description : stdGrid row copy 메서드 */
      async onCopyStdGrid() {
        const selectedRowsData = this.$refs.stdGrid.getGridInstance.getSelectedRowsData();

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DUPLICATE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const payload = {
          actionname: 'EWM_PERFORMANCE_STD_INSERT',
          path: '/' + selectedRowsData[0].kpiPerformStdId,
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      // /** @description : stdGrid 데이터 추가 시 기본 값 적용하기 위함 */
      // onInitNewRow(e) {
      // 	e.data.viewFl = 'Y';
      // },
      /** @description : stdGrid row 추가 메서드 */
      onAddStdGrid() {
        this.onOpenModal(
          'ModalEwmAdd',
          {
            title: '성과기준관리 추가',
            buttons: {
              save: { text: '저장' },
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '400',
          },
          {
            columns: this.stdGrid.columns,
          },
        );
      },
      onUpdateData(data) {
        this.onOpenModal(
          'ModalEwmAdd',
          {
            title: '성과기준관리 수정',
            buttons: {
              save: { text: '수정' },
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '400',
          },
          {
            columns: this.stdGrid.columns,
            formData: {
              id: data.kpiPerformStdId,
              ymd: data.ymd,
              name: data.performStdNm,
              check: data.viewFl == 'Y' ? true : false,
            },
          },
        );
      },
      /** @description : stdGrid row save 메서드 */
      async onSaveStd(e) {
        let payload = {
          actionname: 'EWM_PERFORMANCE_STD_INSERT',
          data: {
            stds: e,
          },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : stdGrid row delete 메서드 */
      async onDeleteStdGridData() {
        //const selectedRowsData = this.$refs.stdGrid.selectedRowsData;
        const selectedRowsData = this.$refs.stdGrid.getGridInstance.getSelectedRowsData()[0];

        if (!selectedRowsData) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (selectedRowsData.useFl == 'Y') {
          this.$_Msg(`사용중입니다.`);
          return;
        }

        if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        let kpiPerformStd = [];
        kpiPerformStd.push({ kpiPerformStdId: selectedRowsData.kpiPerformStdId });

        const payload = {
          actionname: 'EWM_PERFORMANCE_STD_DELETE',
          data: { data: { kpiPerformStdIds: kpiPerformStd } },
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : stdGrid row 선택 메서드 */
      onStdGridSelectionChanged(e) {
        console.log('onStdGridSelectionChanged', e);

        if (e.selectedRowsData.length > 0) {
          this.onRowClick({ data: e.selectedRowsData[0] });
        }
      },
      async onRowClick(row) {
        if (!row.data.kpiPerformStdId) {
          return;
        }

        this.$refs.stdGrid.selectedRowsData = row.data;
        this.$refs.stdGrid.getGridInstance.selectRows([row.data.kpiPerformStdId], false);

        this.canAddStandard = row.data.useFl === this.$_enums.common.stringUsedFlag.NO.value;

        const payload = {
          actionname: 'EWM_PERFORMANCE_STD_SELECT',
          data: {
            kpiPerformStdId: row.data.kpiPerformStdId,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.metricsIdFilter = res.data.data[0].kpiPerformStdMetricsRegs.map(x => x.kpiPerformMetricsId);
          this.metricsGrid.dataSource = res.data.data[0].kpiPerformStdMetricsRegs;

          this.$refs.metricsGrid.getGridInstance.cancelEditData();

          /* const kpiPerformStdIdx = this.stdGrid.dataSource.findIndex(itm => itm.kpiPerformStdId == row.data.kpiPerformStdId);

				const totalBaseScore = this.metricsGrid.dataSource.reduce((sum, current) => sum + current.baseScore, 0);
				if (totalBaseScore != this.stdGrid.dataSource[kpiPerformStdIdx].score) {
					//this.$set(this.stdGrid.dataSource[kpiPerformStdIdx], 'score', totalBaseScore);

					this.$refs.stdGrid.getGridInstance.selectRows([kpiPerformStdIdx], false);
				} */
        }
      },
      /** @description: 기준추가 */
      onAddStandard() {
        if (this.$refs.stdGrid.getGridInstance.getSelectedRowsData().length < 1) {
          this.$_Msg('성과기준을 선택하세요');
          return;
        }

        if (!this.canAddStandard) {
          this.$_Msg(`사용중인 기준 입니다. 수정할 수 없습니다.`);
          return;
        }

        const selectedRowsData = this.$refs.stdGrid.selectedRowsData;
        console.log('onAddStandard selectedRowsData', selectedRowsData);

        if (selectedRowsData.kpiPerformStdId) {
          this.onOpenModal(
            'ModalPerformMetrics',
            {
              title: '성과관리지표',
              buttons: {
                save: { text: '선택' },
                cancel: { text: '닫기' },
              },
              width: '900',
              height: '600',
            },
            this.metricsIdFilter,
          );
        } else {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: row 추가 */
      onConfirmModal() {
        if (
          this.modal.currentComponent === 'ModalEwmAdd' &&
          (this.modal.contentData.columns || this.modal.contentData.ymd == null || this.modal.contentData.name == null)
        ) {
          return;
        }

        if (this.modal.currentComponent === 'ModalPerformMetrics' && !this.modal.contentData.val) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (this.modal.currentComponent === 'ModalPerformMetrics') {
          let obejctArray = [];

          this.modal.contentData.val.forEach(i => {
            if (!this.metricsIdFilter.includes(i.kpiPerformMetricsId)) {
              this.$refs.metricsGrid.getGridInstance.addRow();
              obejctArray.push(i);
            }
          });
          obejctArray.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
              this.$refs.metricsGrid.getGridInstance.cellValue(index, key, value);
            });
          });

          this.metricsIdFilter = this.modal.contentData.val.map(item => item.id);
        } else {
          let data = [];
          data.push({
            kpiPerformStdId: this.modal.contentData.id,
            performStdNm: this.modal.contentData.name,
            ymd: this.modal.contentData.ymd,
            viewFl: this.modal.contentData.check ? 'Y' : 'N',
            useFl: this.$_enums.common.stringUsedFlag.NO.value,
          });
          this.onSaveStd(data);
        }

        this.isOpenModal(false);
      },
      /** @description : 저장 */
      async onSaveMetrics(e) {
        e.cancel = true;

        if (!e.changes.length) {
          return;
        }

        if (
          !(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let data = [];
        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            let rowIndex = e.component.getRowIndexByKey(d.key);
            let row = e.component.getVisibleRows()[rowIndex];
            let updateData = row.data;

            data.push({
              kpiPerformStdMetricsRegId: updateData.kpiPerformStdMetricsRegId,
              kpiPerformMetricsId: updateData.kpiPerformMetricsId,
              performEvalDivision: updateData.performEvalDivision,
              baseScore: updateData.baseScore,
              minScore: updateData.minScore,
              maxScore: updateData.maxScore,
            });
          });
        }

        const selectedRowsData = this.$refs.stdGrid.selectedRowsData;

        const payload = {
          actionname: 'EWM_PERFORMANCE_STD_METRICS_REG_INSERT',
          data: {
            kpiPerformStdId: selectedRowsData.kpiPerformStdId,
            metrics: data,
          },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          //vm.onRowClick({ data: this.$refs.stdGrid.getGridInstance.getSelectedRowsData()[0] });
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          this.onRowClick({ data: selectedRowsData });

          // this.selectDataList().then(() => {
          // 	this.$refs.stdGrid.getGridInstance.selectRows([selectedRowsData.kpiPerformStdId], false);
          // });

          /* this.selectDataList().then(() => {
					var row = {
						data: {
							kpiPerformStdId: selectedRowsData.kpiPerformStdId,
						},
					};
					vm.onRowClick(row);
				}); */
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 삭제 */
      async onDeleteMetricsData() {
        if (this.$refs.stdGrid.getGridInstance.getSelectedRowsData().length < 1) {
          this.$_Msg('성과기준을 선택하세요');
          return;
        }

        const selectedStdData = this.$refs.stdGrid.selectedRowsData;
        const selectedRowsData = this.$refs.metricsGrid.selectedRowsData;

        if (selectedStdData.useFl === 'Y') {
          this.$_Msg(`사용중인 기준 입니다. 수정할 수 없습니다.`);
          return;
        }

        const sendData = selectedRowsData.map(x => x.kpiPerformStdMetricsRegId);

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        } else {
          if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
            return;
          }

          const payload = {
            actionname: 'EWM_PERFORMANCE_STD_METRICS_REG_DELETE',
            data: {
              data: {
                kpiPerformStdMetricsRegIds: sendData,
              },
            },
            loading: true,
          };

          const res = await this.CALL_EWM_API(payload);

          if (isSuccess(res)) {
            //vm.onRowClick({ data: this.$refs.stdGrid.getGridInstance.getSelectedRowsData()[0] });
            this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
            this.onRowClick({ data: selectedStdData });
            // this.selectDataList().then(() => {
            // 	this.$refs.stdGrid.getGridInstance.selectRows([selectedRowsData[0].kpiPerformStdId], false);
            // });

            /* this.selectDataList().then(() => {
						var row = {
							data: {
								kpiPerformStdId: selectedRowsData?.[0]?.kpiPerformStdId,
							},
						};
						vm.onRowClick(row);
					}); */
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      onDayStartChanged(e) {
        const changed = e.value.slice(0, 6) + '01';
        this.searchType.customTypes.startDt = changed;
      },
      onDayEndChanged(e) {
        const changed = e.value.slice(0, 6) + '29';
        this.searchType.customTypes.endDt = changed;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_eval_type');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          //this.codes.performEvalDivision.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_eval_type'], 2);
          //this.$refs.metricsGrid.getGridInstance.columnOption('performEvalDivision', 'lookup', this.codes.performEvalDivision);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
